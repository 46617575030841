import React from 'react';
// import { candidateResumeData10 as candidateResumeData } from './resumeData';
import './template1.css';
import { toAbsoluteUrl } from '../../../_metronic/helpers';

export default function Template1({ candidateResumeData }) {

    console.log(candidateResumeData)

    function isKeyEmpty(obj, key) {
        if (!obj.hasOwnProperty(key)) {
            return true;
        }

        const value = obj[key];
        if (typeof value === 'string' && value.trim() === '') {
            return true;
        } else if (Array.isArray(value) && value.length === 0) {
            return true;
        } else if (typeof value === 'object' && Object.keys(value).length === 0) {
            return true;
        }

        return false;
    }

    function capitalizeAndReplace(str) {
        // Capitalize the first letter
        str = str.charAt(0).toUpperCase() + str.slice(1);
        // Replace underscores with spaces
        str = str.replace(/_/g, ' ');
        return str;
    }

    function renderValue(value) {
        if (typeof value === 'string' || typeof value === 'number') {
            return <>{value}</>;
        } else if (Array.isArray(value)) {
            return <ul>{value.map((item, index) => <li key={index}>{renderValue(item)}</li>)}</ul>;
        } else if (typeof value === 'object') {
            return (
                <ul>
                    {Object.entries(value).map(([key, val]) => (
                        <li key={key}>
                            <span className='fw-bold'>{capitalizeAndReplace(key)}: </span>
                            {renderValue(val)}
                        </li>
                    ))}
                </ul>
            );
        }
    }

    function renderOverviewSection() {
        if (!isKeyEmpty(candidateResumeData, "overview")) {
            return (
                <section>
                    <div className='row'>
                        <p className='fw-bold text-decoration-underline'>OVERVIEW:</p>
                    </div>
                    {renderValue(candidateResumeData.overview)}
                </section>
            );
        }
        return null;
    }

    function renderProfileSection() {
        if (!isKeyEmpty(candidateResumeData, "summary")) {
            return (
                <section>
                    <div className='row'>
                        <p className='fw-bold text-decoration-underline'>PROFILE:</p>
                    </div>
                    {renderValue(candidateResumeData.summary)}
                </section>
            );
        }
        return null;
    }

    function renderExperienceSection() {
        if (!isKeyEmpty(candidateResumeData, "work_experience")) {
            return (
                <section>
                    <div className='row'>
                        <p className='fw-bold text-decoration-underline'>EXPERIENCE:</p>
                    </div>
                    {renderWorkExperience()}
                </section>
            );
        }
        return null;
    }

    function renderWorkExperience() {
        return (
            <>
                {Array.isArray(candidateResumeData.work_experience) ?
                    candidateResumeData.work_experience.map((experience, index) => (
                        <div key={index} className='col-12'>
                            <div className='d-flex justify-content-between'>
                                {(!experience.company && !experience.location) ?
                                    <p className='p-0 m-0 fw-bold fst-italic'>
                                        {experience.title}
                                    </p> :
                                    <p className='p-0 m-0 fw-bold'>
                                        {experience.company} {experience.location && `,`} {experience.location}
                                    </p>
                                }
                                <p className='p-0 m-0 fw-bold'>{experience.time_period}</p>
                            </div>
                            <p className='p-0 m-0 fw-bold fst-italic'>{!(!experience.company && !experience.location) && experience.title}</p>
                            {renderValue(experience.responsibilities)}
                            {renderValue(experience.remaining_related_data)}
                            {renderAdditionalExperienceData(experience)}
                            <br />
                        </div>
                    )) :
                    <div className='col-12'>
                        <div className='d-flex justify-content-between'>
                            <p className='p-0 m-0 fw-bold'>{candidateResumeData.work_experience.company} {candidateResumeData.work_experience.location && `,`} {candidateResumeData.work_experience.location}</p>
                            <p className='p-0 m-0 fw-bold'>{candidateResumeData.work_experience.time_period}</p>
                        </div>
                        <p className='p-0 m-0 fw-bold fst-italic'>{candidateResumeData.work_experience.title}</p>
                        {renderValue(candidateResumeData.work_experience.responsibilities)}
                        {renderValue(candidateResumeData.work_experience.remaining_related_data)}
                        {renderAdditionalExperienceData(candidateResumeData.work_experience)}
                    </div>
                }
            </>
        );
    }

    function renderAdditionalExperienceData(experience) {
        return Object.entries(experience).map(([key, value]) => {
            if (!['title', 'location', 'time_period', 'company', 'responsibilities', 'remaining_related_data'].includes(key)) {
                return (
                    <div key={key}>
                        <p className='p-0 m-0 fw-bold'>{key}: </p>
                        {renderValue(value)}
                    </div>
                );
            }
            return null;
        });
    }

    function renderCertificationsSection() {
        if (!isKeyEmpty(candidateResumeData, "certifications")) {
            const certifications = candidateResumeData.certifications;
            if (Array.isArray(certifications) && certifications.some(certification => certification.certification_details.trim() !== '')) {
                return (
                    <section>
                        <div className='row'>
                            <p className='fw-bold text-decoration-underline'>CERTIFICATIONS:</p>
                        </div>
                        <ul>
                            {certifications.map((certification, index) => (
                                <li key={index}>
                                    <p className='p-0 m-0'>{certification.certification_details} {certification.date && `●`} {certification.date}</p>
                                </li>
                            ))}
                        </ul>
                    </section>
                );
            } else if (!Array.isArray(certifications) && certifications.certification_details.trim() !== '') {
                return (
                    <section>
                        <div className='row'>
                            <p className='fw-bold text-decoration-underline'>CERTIFICATIONS:</p>
                        </div>
                        <ul>
                            <li>
                                <p className='p-0 m-0'>{certifications.certification_details} {certifications.date && `●`} {certifications.date}</p>
                            </li>
                        </ul>
                    </section>
                );
            }
        }
        return null;
    }

    function renderEducationSection() {
        if (!isKeyEmpty(candidateResumeData, "education")) {
            const { education } = candidateResumeData;

            if (Array.isArray(education)) {
                if (education.every(edu => isEmptyEducation(edu))) {
                    return null;
                }

                return (
                    <section>
                        {renderEducationHeader()}
                        {education.map((edu, index) => (
                            <div key={index}>
                                {renderEducationDetails(edu)}
                            </div>
                        ))}
                    </section>
                );
            } else if (typeof education === 'object') {
                const { university, location, time_period, degree, remaining_related_data, ...additionalData } = education;
                if (isEmptyEducation({ university, location, time_period, degree, remaining_related_data, ...additionalData })) {
                    return null;
                }

                return (
                    <section>
                        {renderEducationHeader()}
                        <div>
                            {renderEducationDetails({ university, location, time_period, degree, remaining_related_data, ...additionalData })}
                        </div>
                    </section>
                );
            }
        }
        return null;
    }

    function renderEducationHeader() {
        return (
            <div className='row'>
                <p className='fw-bold text-decoration-underline'>EDUCATION:</p>
            </div>
        );
    }

    function renderEducationDetails(edu) {
        const { university, location, time_period, degree, remaining_related_data, ...additionalData } = edu;

        return (
            <>
                <p className='p-0 m-0 fw-bold'>{university} {location && `●`} {location} {time_period && `●`} {time_period}</p>
                <p className='p-0 m-0 fw-bold fst-italic'>{degree}</p>
                {renderValue(remaining_related_data)}
                {renderAdditionalEducationData(additionalData)}
                <br />
            </>
        );
    }

    function isEmptyEducation(edu) {
        const { university, location, time_period, degree, remaining_related_data, ...additionalData } = edu;
        const additionalDataValues = Object.values(additionalData);
        if ((typeof university === 'string' && university.trim() === '') &&
            (typeof location === 'string' && location.trim() === '') &&
            (typeof degree === 'string' && degree.trim() === '') &&
            (typeof time_period === 'string' && time_period.trim() === '') &&
            (typeof remaining_related_data === 'string' && remaining_related_data.trim() === '') &&
            additionalDataValues.every(value => typeof value === 'string' && value.trim() === '')) {
            return true;
        }
        return false;
    }

    function renderAdditionalEducationData(edu) {
        return Object.entries(edu).map(([key, value]) => {
            if (!['university', 'location', 'time_period', 'degree', 'remaining_related_data'].includes(key)) {
                return (
                    <div key={key}>
                        <p className='p-0 m-0 fw-bold'>{key}: </p>
                        {renderValue(value)}
                    </div>
                );
            }
            return null;
        });
    }

    function renderSkillsSection() {
        if (!isKeyEmpty(candidateResumeData, "skills")) {
            const { skills } = candidateResumeData;

            if (Array.isArray(skills)) {
                if (skills.every(skill => isEmptySkill(skill))) {
                    return null;
                }

                return (
                    <section>
                        {renderSkillsHeader()}
                        {skills.map((skill, index) => (
                            <div key={index}>
                                {renderSkill(skill)}
                            </div>
                        ))}
                    </section>
                );
            } else if (typeof skills === 'object') {
                const { heading, related_data } = skills;

                if (isEmptySkill({ heading, related_data })) {
                    return null;
                }

                return (
                    <section>
                        {renderSkillsHeader()}
                        <div>
                            {renderSkill({ heading, related_data })}
                        </div>
                    </section>
                );
            }
        }
        return null;
    }

    function renderSkillsHeader() {
        return (
            <div className='row'>
                <p className='fw-bold text-decoration-underline'>SKILLS:</p>
            </div>
        );
    }

    function renderSkill(skill) {
        const { heading, related_data } = skill;
        if (related_data === '') {
            return (
                <ul>
                    <li>
                        {renderValue(heading)}
                    </li>
                </ul>
            )
        }
        else if (heading === "") {
            return renderValue(related_data);
        } else {
            return (
                <>
                    {renderValue(heading)}
                    {Array.isArray(related_data) ? renderValue(related_data) : <ul>{renderValue(related_data)}</ul>}
                </>
            );
        }
    }

    function isEmptySkill(skill) {
        const { heading, related_data } = skill;
        if ((typeof heading === 'string' && heading.trim() === '') && (typeof related_data === 'string' && related_data.trim() === '')) {
            return true;
        }
        return false;
    }

    function renderAdditionalSection() {
        if (!isKeyEmpty(candidateResumeData, "additional")) {
            return (
                <section>
                    <div className='row'>
                        <p className='fw-bold text-decoration-underline'>ADDITIONAL:</p>
                    </div>
                    {renderValue(candidateResumeData.additional)}
                </section>
            );
        }
        return null;
    }

    return (
        <>
            <div className='template-body'>
                <div className='d-flex justify-content-between'>
                    <p className='template-name'>{candidateResumeData.name}</p>
                    <img src={toAbsoluteUrl("/media/logos/forum_logo.png")} alt="Forum logo" height={45} />
                </div>

                {renderOverviewSection()}
                <br />
                {renderProfileSection()}
                <br />
                {renderExperienceSection()}
                <br />
                {renderCertificationsSection()}
                <br />
                {renderEducationSection()}
                <br />
                {renderSkillsSection()}
                <br />
                {renderAdditionalSection()}
            </div>
        </>
    );
}
