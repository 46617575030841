import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import React from 'react';
import { Blocks } from 'react-loader-spinner';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
import { useAuth } from '../auth';
import Template1 from '../resumeTemplate/Template1';

interface ResultUIProps {
    preview: boolean;
    selectedFiles: any[]; // Adjust the type accordingly
    processAction?: string | null; // Adjust the type accordingly
    toPDF: () => void;
    targetRef: React.RefObject<HTMLDivElement>;
    resumeJson: any; // Adjust the type accordingly
    formatting: boolean;
    processedFileLink?: string | null;
    handleDocumentApproval: (action: string) => void; // New prop for handleDocumentApproval
}

const ResultUI: React.FC<ResultUIProps> = ({
    preview,
    selectedFiles,
    processAction,
    toPDF,
    targetRef,
    resumeJson,
    formatting,
    processedFileLink,
    handleDocumentApproval, // Add handleDocumentApproval prop
}) => {
    const { currentUser } = useAuth()

    return (
        <div className={`col-sm-12 ${preview ? "col-md-6" : "col-md-12"}`}>
            <div className={`card card-xl-stretch mb-5 mb-xl-8`} style={{ height: "70vh", overflowY: "scroll" }}>
                <div className="card-header border-0 p-0 align-items-center">
                    <h3 className="card-title align-items-center fw-bold p-2">
                        <KTIcon
                            iconName="screen"
                            className="fs-2x text-primary"
                        />
                        &nbsp; Processed Resume
                    </h3>
                    <span>
                        {
                            <>
                                <>
                                    <button
                                        type="button"
                                        className="btn btn-success me-3 w-120px fs-9 p-2"
                                        onClick={(e) => { handleDocumentApproval("accepted") }}
                                        disabled={selectedFiles.length == 0}
                                    >
                                        {
                                            processAction == "accepted" ?
                                                <span className="indicator-progress" style={{ display: "block" }}>
                                                    Processing...{" "}
                                                    <span className="spinner-border align-middle fs-6"></span>
                                                </span>
                                                :
                                                <span className='d-flex align-items-center justify-content-between'>
                                                    <img src={toAbsoluteUrl("/media/custom-icon/accept-file.png")} alt="Thirdeye data" height={15} /> &nbsp;
                                                    {/* <KTIcon iconName='filter-tick' className='fs-2' /> */}
                                                    Accept
                                                </span>
                                        }
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-danger me-3 w-120px fs-9 p-2"
                                        disabled={selectedFiles.length == 0}
                                        onClick={(e) => { handleDocumentApproval("rejected") }}
                                    >
                                        {
                                            processAction == "rejected" ?
                                                <span className="indicator-progress" style={{ display: "block" }}>
                                                    Processing...{" "}
                                                    <span className="spinner-border align-middle fs-6"></span>
                                                </span>
                                                :
                                                <span className='d-flex align-items-center justify-content-between'>
                                                    <img src={toAbsoluteUrl("/media/custom-icon/reject-file.png")} alt="Thirdeye data" height={15} /> &nbsp;
                                                    {/* <KTIcon iconName='cross-square' className='fs-2' /> */}
                                                    Reject
                                                </span>
                                        }
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-warning me-3 w-180px fs-9 p-2"
                                        data-bs-toggle="modal"
                                        data-bs-target="#kt_modal_feedback"
                                        disabled={selectedFiles.length == 0}
                                    >
                                        {
                                            processAction == "feedback" ?
                                                <span className="indicator-progress" style={{ display: "block" }}>
                                                    Processing...{" "}
                                                    <span className="spinner-border align-middle fs-6"></span>
                                                </span>
                                                : <span className='d-flex align-items-center justify-content-between'>
                                                    <img src={toAbsoluteUrl("/media/custom-icon/feedback-file.png")} alt="Thirdeye data" height={15} /> &nbsp;
                                                    {/* <KTIcon iconName='filter-tick' className='fs-2' /> */}
                                                    Feedback
                                                </span>
                                        }
                                    </button>
                                </>
                                {
                                    currentUser?.usertype == 'superadmin' &&
                                    <button
                                        type="button"
                                        disabled={selectedFiles.length == 0}
                                        className="btn btn-primary me-3 w-180px fs-9 p-2"
                                        data-bs-toggle="modal"
                                        data-bs-target="#kt_modal_assign"
                                    >
                                        {
                                            processAction == "assinging" ?
                                                <span className="indicator-progress" style={{ display: "block" }}>
                                                    Processing...{" "}
                                                    <span className="spinner-border align-middle fs-6"></span>
                                                </span>
                                                : <span className='d-flex align-items-center justify-content-between'>
                                                    <img src={toAbsoluteUrl("/media/custom-icon/assign-file.png")} alt="Thirdeye data" height={15} /> &nbsp;
                                                    {/* <KTIcon iconName='filter-tick' className='fs-2' /> */}
                                                    Assign
                                                </span>
                                        }
                                    </button>
                                }
                            </>
                        }
                    </span>
                </div>
                {
                    currentUser?.email == "forum@thirdeyedata.ai" ?
                        <div className="rounded p-5 bg-dark text-light overflow-auto h-100 border border-3 border-primary">
                            <button className="btn btn-primary me-3 my-3 w-180px " onClick={() => toPDF()}>Download PDF</button>
                            <div ref={targetRef}>
                                <Template1 candidateResumeData={resumeJson} />
                            </div>
                        </div> :
                        <>
                            {!processedFileLink ? (
                                <div className="row align-items-center">
                                    {formatting ? (
                                        <Blocks
                                            visible={true}
                                            height="80"
                                            width="80"
                                            ariaLabel="blocks-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="blocks-wrapper"
                                        />
                                    ) :
                                        <div className="card-body">
                                            <h4 className="text-center">The processed resume will be display here</h4>
                                        </div>
                                    }
                                </div>
                            ) : (
                                <div className="rounded p-5 bg-dark text-light overflow-auto h-100 border border-3 border-primary">
                                    {processedFileLink &&
                                        <DocViewer
                                            documents={[{ uri: processedFileLink, fileType: 'doc' }]}
                                            pluginRenderers={DocViewerRenderers}
                                            style={{ height: "450px" }}
                                        />
                                    }
                                </div>
                            )}
                        </>
                }
            </div>
        </div>
    );
};

export default ResultUI;
