import React from 'react';

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Blocks } from 'react-loader-spinner';
import { KTIcon } from '../../../_metronic/helpers';

type FilePreviewProps = {
    uploadedFileLink?: string | null;
    pdfFileName?: string | null;
    loading: boolean;
};

const FilePreview = ({ uploadedFileLink, pdfFileName, loading }: FilePreviewProps) => {
    return (
        <div className="col-sm-12 col-md-6">
            <div className="card card-xl-stretch mb-5 mb-xl-8" style={{ minHeight: "70vh" }}>
                <div className="card-header border-0">
                    <h3 className="card-title fw-bold text-dark">
                        <KTIcon iconName="screen" className="fs-2x text-primary" />
                        &nbsp; Preview Document
                    </h3>
                </div>
                {uploadedFileLink ? (
                    <>
                        {!uploadedFileLink.toLowerCase().endsWith(".pdf") ? (
                            <div className="rounded p-5 bg-dark text-light overflow-auto h-100 border border-3 border-primary">
                                <DocViewer
                                    documents={[{ uri: uploadedFileLink, fileType: 'doc' }]}
                                    pluginRenderers={DocViewerRenderers}
                                    style={{ height: "450px" }}
                                />
                            </div>
                        ) : (
                            <iframe
                                src={`/${pdfFileName?.substring(pdfFileName.indexOf('media'))}`}
                                title="PDF Preview"
                                width="100%"
                                height="500px"
                            ></iframe>
                        )}
                    </>
                ) : (
                    <div className="row align-items-center">
                        {loading ? (
                            <Blocks
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                            />
                        ) : (
                            <div className="card-body">
                                <h4 className="text-center">The uploaded resume will be displayed here</h4>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default FilePreview;
