import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import {
  DropEvent,
  FileRejection,
  useDropzone
} from "react-dropzone";
import { pdfjs } from "react-pdf";
import { usePDF } from 'react-to-pdf';
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";
import { cronjob_data, getAllMember, getResumeForumData, getResumeInfo, processResume, processResumeForum, resumeApproval, resumeAssignTo, resumeFeedback, resumeMove, savePdfPreview, startContinuousProcess, uploadResume } from "../../api";
import { useAuth } from "../../modules/auth";
import ButtonRow from "../../modules/resumeformatting/ButtonRow";
import FilePreview from "../../modules/resumeformatting/FilePreview";
import Header from "../../modules/resumeformatting/HeaderSection";
import ResultUI from "../../modules/resumeformatting/ResultUI";
import ResumeLibrary from "../../modules/resumeformatting/ResumeLibrary";
import { UploadResumeForm } from "../../modules/resumeformatting/UploadResumeForm";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {};

type fileType = Array<string>;

const bgList = ["bg-success", "bg-primary", "bg-warning"];

type MyObject = {
  [key: string]: string[];
};

type StyleObject = {
  headingFontStyle: string,
  headingFontSize: string,
  contentFontStyle: string,
  contentFontSize: string
}

type ResumeDataObject = {
  original_file_name: string,
  process_date: string
  process_file_url: string,
  process_time: string,
  processed: number,
  processed_file_name: string,
  tag: string,
  upload_date: string,
  uploaded_file_url: string,
  useremail: string,
  assign_to: string,
  feedback: string,
  approval: string
}

const resumes = [
  {
    id: "forum_new",
    img: toAbsoluteUrl("/media/resume/foremresume.png")
  },
  {
    id: "thirdeye",
    img: toAbsoluteUrl("/media/resume/thirdeyeresume.png")
  },
  {
    id: "vpinternational",
    img: toAbsoluteUrl("/media/resume/vpresume.png")
  },
];

const fontSizes = ["10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"]
const fontStyles = ["Oswald", "Arial", "Lexend", "Lora", "Times New Roman", "Roboto", "Calibri", "EB Garamond", "Montesrrat", "Lato"]
interface TeamMemberObject {
  username: string;
  email: string;
  password: string;
  organizationId: number;
  type: string;
}

type CronJobReqObject = {
  scheduledTime: string;
  scheduleFreq: string;
  year?: number | null;
  month?: number | null;
  day?: number | null;
  dayOfWeek?: number | null;
  hours?: number | null;
  minutes?: number | null;
  seconds?: number | null;
  milliseconds?: number | null;
};

export default function ResumeFormat({ }: Props) {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [formatting, setFormatting] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [preview, setPreview] = useState<boolean>(false);
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [selectedFilesUpload, setSelectedFilesUpload] = useState<File[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [pdfFile, setPdfFile] = useState<File>();
  const [file, setFile] = useState<File | null>(null);
  const [url, setUrl] = useState("");
  const [styleData, setStyleData] = useState<StyleObject>({
    headingFontStyle: 'Lato', // Initial value for headingFontStyle
    headingFontSize: '14',    // Initial value for headingFontSize
    contentFontStyle: 'Roboto', // Initial value for contentFontStyle
    contentFontSize: '11',    // Initial value for contentFontSize
  })
  const [resumeData, setResumeData] = useState<ResumeDataObject[]>([])
  const [unResumeData, setUnResumeData] = useState<ResumeDataObject[]>([])
  const [result, setResult] = useState<Array<MyObject>>([]);
  const [formatType, setFormatType] = useState<string>("thirdeye")
  const [fileName, setFileName] = useState<Array<string>>([]);
  const [formatedResume, setFormatedResume] = useState<string>("")
  const [fileLink, setFileLink] = useState<string>()
  const [uploadedFileLink, setUploadedFileLink] = useState<string>()
  const [processedFileLink, setProcessedFileLink] = useState<string>()
  const [tab, setTab] = useState("processed");
  const [refresh, setRefresh] = useState<boolean>(false)
  const [processAction, setProcessAction] = useState<string | null>(null)
  const [teams, setTeams] = useState<string[]>()
  const [feedback, setFeedback] = useState<string>()
  const [teamMember, setTeamMember] = useState<string>()
  const [pdfFileName, setPdfFileName] = useState<string>()
  const { toPDF, targetRef } = usePDF({ filename: 'forum_generated_resume.pdf' });
  const [docs, setDocx] = useState(
    [
      {
        uri: "",
        fileType: "docx",
      }
    ]
  )
  const [scheduleTime, setScheduleTime] = useState<string>();
  const [scheduleFreq, setScheduleFreq] = useState<string>("");
  const [resumeJson, setResumeJson] = useState({})

  const handleDownload = async () => {
    try {
      var formatedResumePath = formatedResume.slice(formatedResume.indexOf('media'), formatedResume.length)
      var formatedResumeName = formatedResume.split("/").pop()
      const response = await axios.get(docs[0].uri,
        { responseType: "blob" }) // This ensures that the response is treated as a binary blob

      const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.wordformattingml.document" });


      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create a link element and trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = formatedResumeName ? formatedResumeName : "Resume";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  const handleBulkFormat = () => {
    let formatType = currentUser?.email == "demo@thirdeyedata.ai" ? "thirdeye" : "vp";
    // alert("Curently Unprocessed Resumes will be processed. Sit Back and Enjoy")
    alert("Bulk Format is not Supported in Trial Plan")
    // bulkResumeProcess({ "email": currentUser?.email, "userName": currentUser?.username, formatType: formatType }).then((res) => {
    //   console.log(res)
    // })
  }

  const handleResumeProcess = (link: string) => {
    setFormatting(true);
    let fileList: Array<Object> = [];
    console.log(fileLink)

    // selectedFiles.forEach((selectedFile) => {
    //   let fileObj = {
    //     fileLink: selectedFile
    //   };
    //   fileList.push(fileObj);
    // });

    let fileObj = {
      fileLink: link
    };
    fileList.push(fileObj);
    if (currentUser?.email == "forum@thirdeyedata.ai") {
      processResumeForum({
        files: fileList,
        userName: currentUser?.username,
        email: currentUser?.email,
        orgId: currentUser?.organizationId
      }).then((res) => {

        if (res.status === 400) {
          // Check the response data and display it in an alert
          if (res.data && res.data.message) {
            alert(`Status 426 received: ${res.data.message}`);
          } else {
            alert('Status 426 received.');
          }
        } else {
          // If the status code is not 426, proceed with the usual logic
          // setResumeJson(res.data[0].data);
          // setProcessedFileLink(res.data[0].file_url);
          getResumeInfo({ useremail: currentUser?.email, process: 1, usertype: currentUser?.usertype }).then((resProcessInfo) => {
            setResumeData(resProcessInfo.data);
            getResumeInfo({ useremail: currentUser?.email, process: 0, usertype: currentUser?.usertype }).then((resUnProcessInfo) => {
              setUnResumeData(resUnProcessInfo.data);
              setFormatting(false);
              setFetching(false);
              setTab('processed');
            });
          });
          setFormatting(false);
          console.log(res);
        }
      }).catch((error) => {
        console.log("err :", error.response.status)
        if (error.response.status === 426) {
          alert(error.response.data.data)
          setFormatting(false)
        } else if (error.response.status === 413) {
          alert("Resume data limit exceeded. Please try another resume")
          setFormatting(false)
        } else if (error.response.status === 500) {
          alert("Facing Network Issue. Please Try again")
          setFormatting(false)
        } else {
          alert("Facing Network Issue. Please Try again")
          setFormatting(false)
        }
        setFormatting(false)
      })

    } else {
      let selectedFormatType = currentUser?.email == "demo@thirdeyedata.ai" ? formatType : "vp";
      processResume({
        files: fileList,
        userName: currentUser?.username,
        email: currentUser?.email,
        formatType: selectedFormatType,
        orgId: currentUser?.organizationId
      }).then((res) => {
        if (res.status === 400) {
          // Check the response data and display it in an alert
          if (res.data && res.data.message) {
            alert(`Status 426 received: ${res.data.message}`);
          } else {
            alert('Status 426 received.');
          }
        } else {
          // If the status code is not 426, proceed with the usual logic
          // setResumeJson(res.data[0].data);
          // setProcessedFileLink(res.data[0].file_url);
          getResumeInfo({ useremail: currentUser?.email, process: 1, usertype: currentUser?.usertype }).then((res) => {
            setResumeData(res.data);
            getResumeInfo({ useremail: currentUser?.email, process: 0, usertype: currentUser?.usertype }).then((res) => {
              setUnResumeData(res.data);
              setFormatting(false);
              setFetching(false);
              setTab('processed');
            });
          });
          setFormatting(false);
          console.log(res);
        }
      }).catch((error) => {
        console.log("err :", error.response.status)
        if (error.response.status === 426) {
          alert(error.response.data.data)
          setFormatting(false)
        } else if (error.response.status === 413) {
          alert("Resume data limit exceeded. Please try another resume")
          setFormatting(false)
        } else if (error.response.status === 500) {
          alert("Facing Network Issue. Please Try again")
          setFormatting(false)
        } else {
          alert("Facing Network Issue. Please Try again")
          setFormatting(false)
        }
      })
    }
  }

  const handleUpload = (event: React.FormEvent) => {
    setLoading(true);
    setCloseModal(true)
    event.preventDefault();

    if (selectedFilesUpload.length > 0) {
      const formData = new FormData();
      let fileArr = [];
      selectedFilesUpload.forEach((file, index) => {
        formData.append("files", file, `${file.name}`);
      });
      formData.append(
        "username",
        currentUser ? currentUser.username : "Demo"
      );
      formData.append(
        "useremail",
        currentUser ? currentUser.email : "demo@thirdeyedata.ai"
      );
      formData.append("tag", "resume")

      // Use the formData object as needed, such as sending it via AJAX or API request
      // Example using fetch API:
      uploadResume(formData)
        .then((response: any) => {
          setLoading(false);
          setUploadedFileLink(response.data.files[0].fileUrl)
          setFileLink(response.data.files[0].fileUrl)
          getResumeInfo({ useremail: currentUser?.email, process: 0, usertype: currentUser?.usertype }).then((res) => {
            console.log(res.data)
            setUnResumeData(res.data)
            setFormatting(false)
            setFileName([])
            setSelectedFilesUpload([])
            setTab('unprocessed')
            setCloseModal(false)
          })
        })
        .then((data) => { })
        .catch((error) => {
          setLoading(false)
          setCloseModal(false)
          // Handle error
        });
      setCloseModal(false)
    }
    setCloseModal(false)
  }

  const handleDocumentApproval = (approval: string) => {
    console.log(selectedFiles)
    if (selectedFiles.length > 0) {
      setProcessAction(approval)
      let files: Array<Object> = [];
      selectedFiles.forEach((selectedFile) => {
        let fileObj = {
          file_url: selectedFile
        };
        files.push(fileObj);
      });
      resumeApproval({
        useremail: currentUser?.email,
        files: files,
        approval: approval
      }).then((res) => {
        setProcessAction(null)
        alert(res.message)
        setRefresh(true)
      })
    } else {
      alert("No file selected")
    }
  }

  const handleAssingMember = (e: any) => {
    e.preventDefault()
    if (selectedFiles.length > 0) {
      setProcessAction("assinging")
      let files: Array<Object> = [];
      selectedFiles.forEach((selectedFile) => {
        let fileObj = {
          file_url: selectedFile
        };
        files.push(fileObj);
      });
      resumeAssignTo({
        useremail: currentUser?.email,
        files: files,
        assignTo: teamMember
      }).then((res) => {
        setProcessAction(null)
        alert(res.message)
        setRefresh(true)
      })
    } else {
      alert("No file selected")
    }
  }

  const handleSubmitFeedback = (e: any) => {
    // e.preventDefault()
    if (selectedFiles.length > 0) {
      setProcessAction("feedback")
      let files: Array<Object> = [];
      selectedFiles.forEach((selectedFile) => {
        let fileObj = {
          file_url: selectedFile
        };
        files.push(fileObj);
      });
      resumeFeedback({
        useremail: currentUser?.email,
        files: files,
        feedback: feedback
      }).then((res) => {
        setProcessAction(null)
        alert(res.message)
        setRefresh(true)
      })
    } else {
      alert("No file selected")
    }
  }

  // const handleCopy = () => {
  //   navigator.clipboard.writeText(result);
  // };

  const handlePreview = async () => {
    setUploadedFileLink("")
    setProcessedFileLink("")
    setSelectedFiles([])
    setPreview(!preview);
  };

  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      // Handle file changes here

      if (acceptedFiles) {
        let tempFileNames: string[] = [];
        acceptedFiles.map((file) => {
          tempFileNames.push(file.name);
        });
        setFileName([...tempFileNames]);
        let seletedFile = acceptedFiles[0];
        setPdfFile(seletedFile);
        if (acceptedFiles && acceptedFiles[0]) {
          const uploadedFile = acceptedFiles[0];
          setFile(uploadedFile);
        }
        const fileList = Array.from(acceptedFiles);
        setSelectedFilesUpload(fileList);
      }
    },
    []
  );

  const compare = (originalFileName: string, uploadedFileLink: string, processedFileLink: string) => {
    console.log(processedFileLink, uploadedFileLink)
    setUploadedFileLink(uploadedFileLink);
    setProcessedFileLink(processedFileLink);
    let tempSelectedFiles = [];
    tempSelectedFiles.push(uploadedFileLink)
    setSelectedFiles(tempSelectedFiles)
    if (currentUser?.email == 'forum@thirdeyedata.ai') {
      if (uploadedFileLink.toLowerCase().endsWith(".pdf")) {
        savePdfPreview(
          {
            "original_file_name": originalFileName,
            "uploaded_file_url": uploadedFileLink,
            "useremail": currentUser?.email,
          }
        ).then((res) => {
          console.log(res)
          setPdfFileName(res.fileName)
        })
      }
      setPreview(true)
      getResumeForumData({ file_url: uploadedFileLink, user_email: currentUser?.email }).then((res) => {
        setResumeJson(res.data)
        setPreview(true)
      })
    } else {

      if (uploadedFileLink.toLowerCase().endsWith(".pdf")) {
        savePdfPreview(
          {
            "original_file_name": originalFileName,
            "uploaded_file_url": uploadedFileLink,
            "useremail": currentUser?.email,
          }
        ).then((res) => {
          console.log(res)
          setPdfFileName(res.fileName)
        })
      }
      setPreview(true)
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSetLink = (link: string) => {
    setFileLink(link)
  }

  function padZero(num: number): string {
    return num.toString().padStart(2, "0");
  }

  const handleScheduling = () => {
    // Create a Date object for a specific date and time (year, month, day, hours, minutes, seconds, milliseconds)
    if (scheduleTime) {
      const specificDate: Date = new Date(scheduleTime);
      // Get the year, month, day, hours, minutes, seconds, and milliseconds from the Date object
      const year: number = specificDate.getUTCFullYear();
      const month: number = specificDate.getUTCMonth() + 1;
      const day: number = specificDate.getUTCDate();
      const dayOfWeek: number = specificDate.getUTCDay();
      const hours: number = specificDate.getUTCHours();
      const minutes: number = specificDate.getUTCMinutes();
      const seconds: number = specificDate.getUTCSeconds();
      const milliseconds: number = specificDate.getUTCMilliseconds();
      const utcTime = `${year}-${padZero(month)}-${padZero(day)} ${padZero(
        hours
      )}:${padZero(minutes)}:${padZero(seconds)}:${padZero(milliseconds)}`;

      // const seconds: number = specificDate.getSeconds();
      // const milliseconds: number = specificDate.getMilliseconds();
      let cronjobReqObj: CronJobReqObject = {
        scheduledTime: utcTime,
        scheduleFreq: scheduleFreq
      };
      if (scheduleFreq == "") {
        cronjobReqObj["hours"] = hours;
        cronjobReqObj["minutes"] = minutes;
        cronjobReqObj["day"] = day;
        cronjobReqObj["month"] = month;
      } else if (scheduleFreq == "Daily") {
        cronjobReqObj["hours"] = hours;
        cronjobReqObj["minutes"] = minutes;
      } else if (scheduleFreq == "Weekly") {
        cronjobReqObj["hours"] = hours;
        cronjobReqObj["minutes"] = minutes;
        cronjobReqObj["dayOfWeek"] = dayOfWeek;
      } else if (scheduleFreq == "Monthly") {
        cronjobReqObj["hours"] = hours;
        cronjobReqObj["minutes"] = minutes;
        cronjobReqObj["day"] = day;
      } else {
        alert("Please Select Valid Repetation Frequency");
      }
      cronjob_data(cronjobReqObj)
        .then((res) => {
          alert(`Cron Job Created for ${hours}:${minutes}`);
        })
        .catch((e) => {
          alert("Error Creating CronJob");
        });
    } else {
      alert("Please Select Date and Time");
    }
  };

  const startContinousProcess = () => {
    startContinuousProcess().then((res: any) => {
      alert("Files processing started successfully")
    })
  }

  useEffect(() => {
    if (url != "") {
      setPdfFile(undefined);
      setFileName([]);
      setResult([]);
      let fileInput = window.document.getElementById("file-input");
    }

    if (pdfFile) {
      setUrl("");
    }
  }, [url, pdfFile]);

  useEffect(() => {
    setFetching(true)
    getResumeInfo({ useremail: currentUser?.email, process: 1, usertype: currentUser?.usertype }).then((res) => {
      setResumeData(res.data)
      // setFormatting(false)
      getResumeInfo({ useremail: currentUser?.email, process: 0, usertype: currentUser?.usertype }).then((res) => {
        setUnResumeData(res.data)
        setFormatting(false)
        setFetching(false)
      })
    })
  }, [])

  useEffect(() => {
    if (refresh) {
      setFetching(true)
      setResumeData([])
      setUnResumeData([])
      getResumeInfo({ useremail: currentUser?.email, process: 1, usertype: currentUser?.usertype }).then((res) => {
        setResumeData(res.data)
        // setFormatting(false)
        getResumeInfo({ useremail: currentUser?.email, process: 0, usertype: currentUser?.usertype }).then((res) => {
          setUnResumeData(res.data)
          setFormatting(false)
          setFetching(false)
          setRefresh(false)
        })
      })
    }
  }, [refresh])

  useEffect(() => {
    resumeMove({ "useremail": currentUser?.email, "username": currentUser?.username, "tag": "resume" }).then((res) => {
      // alert("Resume uploaded to Azure") 
    })
  })

  useEffect(() => {
    if (closeModal) {
      let tempArr: string[] = []
      // console.log(fileName)
      setFileName([])
      setSelectedFilesUpload([])
    }
  }, [closeModal])

  useEffect(() => {
    getAllMember({ organizationId: currentUser?.organizationId }).then((res) => {
      //  Filtering out emails of objects with type !== 'superadmin'
      const emails = res.data
        .filter((user: TeamMemberObject) => user.type !== 'superadmin')
        .map((user: TeamMemberObject) => user.email);
      setTeams(emails)
    });
  }, [])

  useEffect(() => {
    console.log(fileLink)
    if (fileLink?.toLowerCase().endsWith(".pdf")) {
      savePdfPreview(
        {
          "original_file_name": "TempFile.pdf",
          "uploaded_file_url": fileLink,
          "useremail": currentUser?.email,
        }
      ).then((res) => {
        console.log(res)
        setPdfFileName(res.fileName)
      })
    }
  }, [fileLink])

  return (
    <div>
      {/* Upload Document */}
      <div
        className="modal fade"
        id="kt_modal_assosiate_student"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Upload Resume</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
                onClick={(e) => { setCloseModal(true) }}
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              {currentUser?.usertype == 'superadmin' ?
                <>
                  <form
                    className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework mb-5"
                    noValidate
                    id="kt_login_signup_form"
                  >
                    <div
                      {...getRootProps()}
                      style={{
                        border: "2px dashed",
                        padding: "100px 40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#1b1b29"
                      }}
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p>Drop the files here...</p>
                      ) : (
                        <p>
                          {fileName.length <= 0 ? (
                            <span className="fw-bold text-dark">Drag media here to upload or connect an account...</span>
                          ) : (
                            <div className="d-flex flex-column">
                              {fileName.map((file: string) => (
                                <div className="badge badge-primary m-1">
                                  <KTIcon iconName="file" className="fs-3" /> &nbsp; {file}
                                </div>
                              ))}
                            </div>
                          )}
                        </p>
                      )}
                    </div>
                  </form>
                  <div className='separator separator-content my-14'>
                    <span className='w-125px text-gray-500 fw-semibold fs-7'>Or</span>
                  </div>
                  <div className='row g-3 mb-9'>
                    <div className='col-md-12'>
                      <UploadResumeForm text={"Connect to External Data Source..."} source={true} />
                    </div>
                  </div>
                  {/* begin::Form group */}
                  <div className="text-center mt-5">
                    <button
                      type="submit"
                      id="kt_sign_up_submit"
                      className="btn btn-lg btn-success w-100 mb-5"
                      data-bs-dismiss="modal"
                      onClick={handleUpload}
                      disabled={selectedFilesUpload.length > 0 ? false : true}
                    >
                      {!loading && <span className="indicator-label">Submit</span>}
                      {loading && (
                        <span className="indicator-progress" style={{ display: "block" }}>
                          Please wait...{" "}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </> :
                <p className="fs-1 fw-bold text-center">Feature Disabled</p>
              }
            </div>
          </div>
        </div>
      </div>

      {/* Resume Formats */}
      <div
        className="modal fade"
        id="kt_modal_select_resume"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-900px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Select a Format</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y">
              <div
                className={`card card-xl-stretch mb-5 p-5`}
                style={{ maxHeight: "60vh", overflowY: "scroll" }}
              >
                <div className="row bg-light rounded">
                  <div className='col-3'>
                    <div className="row mb-4">
                      <label className="col-lg-12 col-form-label fw-bold fs-6">
                        <span className="">Heading Font Style </span>
                      </label>

                      <div className="col-lg-12 fv-row">
                        <select
                          className="form-select form-select-solid"
                          data-kt-select2="true"
                          data-placeholder="Select option"
                          data-allow-clear="true"
                          value={styleData?.headingFontStyle}
                          onChange={
                            (e) => setStyleData((prevStyleData) => ({
                              ...prevStyleData,
                              headingFontStyle: e.target.value,
                            }))
                          }
                        >
                          <option value="">---Select Heading Font Style---</option>
                          {fontStyles.map((fontStyle) => (
                            <option value={fontStyle}>{fontStyle}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className="row mb-4">
                      <label className="col-lg-12 col-form-label fw-bold fs-6">
                        <span className="">Heading Font Size</span>
                      </label>

                      <div className="col-lg-12 fv-row">
                        <select
                          className="form-select form-select-solid"
                          data-kt-select2="true"
                          data-placeholder="Select option"
                          data-allow-clear="true"
                          value={styleData?.headingFontSize}
                          onChange={
                            (e) => setStyleData((prevStyleData) => ({
                              ...prevStyleData,
                              headingFontSize: e.target.value
                            }))
                          }
                        >
                          <option value="">---Select Heading Font Size---</option>
                          {fontSizes.map((fontSize) => (
                            <option value={fontSize}>{fontSize}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className="row mb-4">
                      <label className="col-lg-12 col-form-label fw-bold fs-6">
                        <span className="">Content Font Style </span>
                      </label>

                      <div className="col-lg-12 fv-row">
                        <select
                          className="form-select form-select-solid"
                          data-kt-select2="true"
                          data-placeholder="Select option"
                          data-allow-clear="true"
                          value={styleData?.contentFontStyle}
                          onChange={
                            (e) => setStyleData({
                              ...styleData,
                              contentFontStyle: e.target.value,
                            })
                          }

                        >
                          <option value="">---Select Content Font Style---</option>
                          {fontStyles.map((fontStyle) => (
                            <option value={fontStyle}>{fontStyle}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className="row mb-4">
                      <label className="col-lg-12 col-form-label fw-bold fs-6">
                        <span className="">Content Font Size</span>
                      </label>

                      <div className="col-lg-12 fv-row">
                        <select
                          className="form-select form-select-solid"
                          data-kt-select2="true"
                          data-placeholder="Select option"
                          data-allow-clear="true"
                          value={styleData?.contentFontSize}
                          onChange={
                            (e) => setStyleData({
                              ...styleData,
                              contentFontSize: e.target.value,
                            })
                          }
                        >
                          <option value="">---Select Content Font Size---</option>
                          {fontSizes.map((fontSize) => (
                            <option value={fontSize}>{fontSize}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label className="col-form-label fw-bold fs-6">
                    <span className="">Select Format Type: </span>
                  </label>
                  <div className="row d-flex align-items-center">
                    {resumes.map((resume) => (
                      <div className="col-4">
                        <div
                          className="form-check my-2"
                          style={{ width: "fit-content" }}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id={resume.id}
                            value={formatType}
                            style={{
                              zIndex: "2",
                              position: "relative",
                              backgroundColor: "#1a1a1a"
                            }}
                            onChange={(e) => { setFormatType(resume.id) }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                            style={{
                              position: "relative",
                              left: "-30px",
                              top: "-10px",
                              zIndex: "1"
                            }}
                          >
                            <img src={resume.img} height={200} />
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

              </div>
              <div className="mt-5 text-center">
                {fileLink &&
                  <button
                    className="btn btn-lg btn-success w-100"
                    data-bs-dismiss="modal"
                    onClick={(e) => handleResumeProcess(fileLink)}
                  >
                    Confirm
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Preview Resume */}
      <div className="modal fade" id="kt_modal_preview" aria-hidden="true">
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-650px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <div className="modal-header">
              {/* begin::Modal title */}
              <h2 className="fw-bolder">Preview Document</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <div className="h-600px">
                {fileLink && (
                  <DocViewer
                    documents={[{
                      uri: fileLink,
                      fileType: fileLink?.split('/')?.pop()?.split('.')?.pop() ?? 'docx'
                    }]}
                    pluginRenderers={DocViewerRenderers}
                  />
                )}
                {
                  fileLink &&
                  <>
                    {
                      !fileLink.toLowerCase().endsWith(".pdf") ?
                        <div className="rounded p-5 bg-dark text-light overflow-auto h-100 border border-3 border-primary">
                          <DocViewer
                            documents={[{
                              uri: fileLink,
                              fileType: fileLink?.split('/')?.pop()?.split('.')?.pop() ?? 'docx'
                            }]}
                            pluginRenderers={DocViewerRenderers}
                          />
                        </div>
                        : <>
                          <iframe
                            src={`/${pdfFileName?.substring(pdfFileName.indexOf('media'))}`}
                            title="PDF Preview"
                            width="100%"
                            height="500px"
                          ></iframe>
                        </>
                    }
                  </>
                }
              </div>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>

      {/* Assign Team */}
      <div
        className="modal fade"
        id="kt_modal_assign"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Assign Document to Team Member</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y p-2">
              <div
                className={`card card-xl-stretch mb-2 px-2`}
                style={{ maxHeight: "60vh", overflowY: "scroll" }}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="row mb-6 align-items-center">
                      <label className="col-lg-4 col-form-label fw-bold fs-4 mt-5">
                        <span className="">Team Member</span>
                      </label>

                      <div className="col-lg-8 fv-row">
                        <small className="px-2 fs-8">Select team member who will process or validate the document</small>

                        {
                          teams &&
                          <select
                            className="form-select form-select-solid"
                            data-kt-select2="true"
                            data-placeholder="Select option"
                            data-allow-clear="true"
                            value={teamMember}
                            onChange={(e) => { setTeamMember(e.target.value) }}
                          >
                            <option value="">---Assign Team Member---</option>
                            {teams.map((team) => (
                              <option value={team}>{team}</option>
                            ))}
                          </select>
                        }
                      </div>
                    </div>
                    {/* <div className="row mb-6">
                      <label className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="">Notify Users After</span>
                      </label>

                      <div className="col-lg-8 fv-row">
                        <input
                          type="text"
                          // className="form-select form-select-solid"
                          className='form-control'
                          placeholder='Time'
                        />
                        <small className="px-2 fs-8">Send notification email after specified reviewing hours</small>
                      </div>
                    </div> */}
                  </div>
                </div>

              </div>
              <div className="my-2 px-2 text-center d-flex justify-content-end">
                <button
                  className="btn btn-lg btn-success"
                  data-bs-dismiss="modal"
                  onClick={(e) => handleAssingMember(e)}
                >
                  Assign
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Provide Feedback */}
      <div
        className="modal fade"
        id="kt_modal_feedback"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Give Feedback about the Processed Document</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y p-2">
              <div
                className={`card card-xl-stretch mb-2 px-2`}
                style={{ maxHeight: "60vh", overflowY: "scroll" }}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      {/* <label className="col-12 col-form-label fw-bold fs-2">
                        <span className="px-1">Feedback</span>
                      </label> */}

                      <div className="col-12 fv-row">
                        {/* <small className="px-1 fs-8 mb-1">Based on the extracted data provide feedback</small> */}
                        <textarea
                          value={feedback}
                          onChange={(e) => { setFeedback(e.target.value) }}
                          className='form-control form-control-solid mb-2'
                          rows={3}
                          placeholder='Type your comments here' />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="my-2 px-2 text-center d-flex justify-content-end">
                <button
                  className="btn btn-lg btn-success"
                  data-bs-dismiss="modal"
                  onClick={(e) => handleSubmitFeedback(e)}
                >
                  Give Feedback
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Scheduling Process Resume */}
      <div className="modal fade" id="kt_modal_schedule" aria-hidden="true">
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-450px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <div className="modal-header">
              {/* begin::Modal title */}
              <h2 className="fw-bolder">Schedule Processing</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <div>
                <form>
                  <label>Repeat</label>
                  <select
                    value={scheduleFreq}
                    onChange={(e) => setScheduleFreq(e.target.value)}
                    className="form-control"
                  >
                    <option value="">None</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                  </select>
                  <br />
                  <label htmlFor="datetime">Select Date and Time:</label>
                  <input
                    type="datetime-local"
                    id="datetime"
                    name="datetime"
                    className="form-control"
                    onChange={(e) => {
                      setScheduleTime(e.target.value);
                    }}
                  />
                </form>
              </div>
              <br />
              <button
                type="button"
                className="btn btn-primary me-3 my-3 w-100 fs-5"
                onClick={handleScheduling}
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="timer" className="fs-3" />
                Confirm Schedule
              </button>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>

      <div className="card card-xl-stretch mb-xl-8 bg-light" style={{ borderRadius: '0px' }}>
        {/* begin::Header */}
        <Header title={preview ? "Validate Resumes" : "Resumes Library"} />
        {/* end::Header */}

        {/* begin: Btn Row */}
        <ButtonRow
          preview={preview}
          loading={loading}
          currentUserEmail={currentUser?.email}
          fileLink={fileLink} // Assuming fileLink is available in your main component
          handlePreview={handlePreview}
          handleResumeProcess={handleResumeProcess}
          startContinousProcess={startContinousProcess}
        />

        {/* end :: Btn Row */}

        {
          !preview ?
            <ResumeLibrary
              tabProp={tab}
              resumeData={resumeData}
              unResumeData={unResumeData}
              handleSetLink={handleSetLink}
              fetching={fetching}
              formatting={formatting}
              processAction={processAction}
              handleResumeProcess={handleResumeProcess}
              compare={compare}
              setRefresh={setRefresh}
              handleDocumentApproval={handleDocumentApproval}
              setSelectedFiles={setSelectedFiles}
            /> :
            <div className="card-body pt-5">
              <div className="row d-flex justify-content-between position-relative">

                {/* File Preview UI */}
                <FilePreview
                  uploadedFileLink={uploadedFileLink}
                  pdfFileName={pdfFileName}
                  loading={loading}
                />

                {/* Result UI */}
                <ResultUI
                  preview={preview}
                  selectedFiles={selectedFiles}
                  processAction={processAction}
                  toPDF={toPDF}
                  targetRef={targetRef}
                  resumeJson={resumeJson}
                  formatting={formatting}
                  processedFileLink={processedFileLink}
                  handleDocumentApproval={handleDocumentApproval} // Pass handleDocumentApproval prop
                />
              </div>
            </div>
        }
      </div>
    </div >
  );
}
