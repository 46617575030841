import React from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import { FilePreviewUIProps } from './DocumentProcessingModel';

export const FilePreviewUI: React.FC<FilePreviewUIProps> = ({
    fileName,
    doctype,
}) => {
    const isValidExtension = (filename: string) => /\.(pdf|jpeg|png|jpg)$/i.test(filename.toLowerCase());

    return (
        <div className={`col-sm-12 col-md-6`}>
            <div
                className={`card card-xl-stretch mb-5 mb-xl-8`}
                style={{ minHeight: '70vh', overflowY: 'auto' }}
            >
                <div className="card-header border-0">
                    <h3 className="card-title fw-bold text-dark">
                        <KTIcon
                            iconName="screen"
                            className="fs-2x text-primary"
                        />
                        &nbsp; Preview Document
                    </h3>
                </div>
                {/* Render file preview */}
                {
                    fileName && doctype ? (
                        <>
                            {
                                isValidExtension(fileName) ? (
                                    <iframe
                                        src={`/media/Demo/${doctype}/${fileName}`}
                                        title="File Preview"
                                        width="100%"
                                        height="500px"
                                    ></iframe>
                                ) : (
                                    <div className="card-body d-flex justify-content-center align-items-center border border-2 m-10">
                                        <h4 className="text-center">
                                            Unable to display the {fileName.split('.').pop()} File Format
                                        </h4>
                                    </div>
                                )
                            }
                        </>
                    ) : (
                        <div className="card-body">
                            <h4 className="text-center">Select a document to preview it here</h4>
                        </div>
                    )
                }

                {/* ... */}
            </div>
        </div >
    );
};
